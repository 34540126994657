import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, MenuItem, Grid } from '@mui/material';
import loanDetailStore from '../store';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const LoanTerms = observer(() => {
    const { loanData, updateLoanData } = loanDetailStore;

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        updateLoanData({
            application: {
                ...loanData.application,
                loanTerms: {
                    ...loanData.application.loanTerms,
                    [name]: value,
                },
            },
        });
    };

const handleMaxInterestRateChange = (event) => {
    let value = event.target.value.replace(/%/g, ''); // Remove any existing '%' characters
    if (!isNaN(value) && value !== '') {
        value += '%';
    }
    handleInputChange({
        target: {
            name: event.target.name,
            value,
        },
    });
};
    const handleLoanAmountChange = (event, value) => {
        updateLoanData({
            application: {
                ...loanData.application,
                loanTerms: {
                    ...loanData.application.loanTerms,
                    loanAmountRequested: value,
                },
            },
        });
    };

    return (
        <Box>
            <Typography variant="h6" mb={1}>Loan Terms</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="loan_product"
                        label="Loan Product"
                        value={loanData?.application?.loanTerms?.loan_product || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        select
                    >
                        <MenuItem value="5-Yr Revolving LOC">5-Yr Revolving LOC</MenuItem>
                        <MenuItem value="10-Yr Revolving LOC">10-Yr Revolving LOC</MenuItem>
                        <MenuItem value="1 Month ARM">1 Month ARM</MenuItem>
                        <MenuItem value="1-Yr VRM">1-Yr VRM</MenuItem>
                        <MenuItem value="3-Yr VRM">3-Yr VRM</MenuItem>
                        <MenuItem value="5-Yr VRM">5-Yr VRM</MenuItem>
                        <MenuItem value="10-Yr VRM">10-Yr VRM</MenuItem>
                        <MenuItem value="15-Yr VRM">15-Yr VRM</MenuItem>
                        <MenuItem value="7-Yr Fixed">7-Yr Fixed</MenuItem>
                        <MenuItem value="10-Yr Fixed">10-Yr Fixed</MenuItem>
                        <MenuItem value="15-Yr Fixed">15-Yr Fixed</MenuItem>
                        <MenuItem value="20-Yr Fixed">20-Yr Fixed</MenuItem>
                        <MenuItem value="25-Yr Fixed">25-Yr Fixed</MenuItem>
                        <MenuItem value="30-Yr Fixed">30-Yr Fixed</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CurrencyTextField
                        modifyValueOnWheel={false}
                        variant="outlined"
                        name="loanAmountRequested"
                        label="Loan Amount Requested"
                        value={loanData?.application?.loanTerms?.loanAmountRequested || ''}
                        onChange={handleLoanAmountChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="loan_amortization_years"
                        label="Loan Amortization Years"
                        value={loanData?.application?.loanTerms?.loan_amortization_years || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        select
                    >
                        <MenuItem value="15 Years">15 Years</MenuItem>
                        <MenuItem value="20 Years">20 Years</MenuItem>
                        <MenuItem value="25 Years">25 Years</MenuItem>
                        <MenuItem value="30 Years">30 Years</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="maxInterestRate"
                        label="Maximum Interest Rate"
                        value={loanData?.application?.loanTerms?.maxInterestRate || ''}
                        onChange={handleMaxInterestRateChange}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="loan_payment_frequency"
                        label="Loan Payment Frequency"
                        value={loanData?.application?.loanTerms?.loan_payment_frequency || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        select
                    >
                        <MenuItem value="Monthly">Monthly</MenuItem>
                        <MenuItem value="Semi-Annual">Semi-Annual</MenuItem>
                        <MenuItem value="Annual">Annual</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        </Box>
    );
});

export default LoanTerms;