import React, {useCallback, useMemo} from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const ScheduleJ = observer(({ borrowerData, readOnly, proforma }) => {
  const { updateBSAData, addBSAItem, deleteBSAItem, updateBSAProformaData, addBSAProformaItem, deleteBSAProformaItem, selectedBorrowerId } = loanDetailStore;

  const selectedBorrowerLocalId = useMemo(() => {
    let result;
    if (readOnly || proforma) {
      result = borrowerData.find(borrower => borrower.is_principal === true)["id"];
    } else {
      result = selectedBorrowerId;
    }
    if (!result) {
      console.warn('No borrower found!');
    }
    return result;
  }, [selectedBorrowerId, readOnly, proforma, borrowerData]);

  const selectedBorrower = useMemo(() => {
    return borrowerData.find(borrower => borrower.id === selectedBorrowerLocalId);
  }, [selectedBorrowerLocalId, borrowerData]);

  const handleUpdateItem = useCallback((borrowerId, index, field, value) => {
    if (proforma) {
      updateBSAProformaData(borrowerId, 'j', index, field, value);
    } else {
      updateBSAData(borrowerId, 'j', index, field, value);
    }
  }, [updateBSAData, updateBSAProformaData, proforma]);

  const handleAddItem = useCallback(() => {
    if (proforma) {
      addBSAProformaItem('j');
    } else {
      addBSAItem(selectedBorrowerLocalId, 'j');
    }
  }, [addBSAItem, addBSAProformaItem, proforma, selectedBorrowerLocalId]);

  const handleDeleteItem = useCallback((borrowerId, index) => {
    if (proforma) {
      const borrower = borrowerData.find(b => b.id === borrowerId);
      if (borrower && borrower.bsas?.j) {
        const originalIndex = borrower.bsas.j.findIndex((item, idx) => idx === index);
        if (originalIndex !== -1) {
          deleteBSAProformaItem(borrowerId, 'j', originalIndex);
        }
      }
    } else {
      deleteBSAItem(borrowerId, 'j', index);
    }
  }, [deleteBSAItem, deleteBSAProformaItem, proforma, borrowerData]);

  const getFieldValue = useCallback((item, field) => {
    return proforma ? (item.proforma?.[field] ?? item[field] ?? '') : (item[field] ?? '');
  }, [proforma]);

  const renderScheduleItems = () => {
    if (readOnly) {
      return borrowerData.flatMap(borrower => borrower.bsas?.j || []);
    }

    if (proforma) {
      return borrowerData.flatMap((borrower, borrowerIndex) =>
          (borrower.bsas?.j || []).map((item, itemIndex) => ({
            ...item,
            ...(item.proforma || {}),
            borrowerId: borrower.id,
            index: itemIndex,  // Add this line
          }))
      );
    }

    return (selectedBorrower.bsas?.j || []).map((item, index) => ({ ...item, index }));
  };

  const scheduleItems = renderScheduleItems();

  const calculateTotals = () => {
    return scheduleItems.reduce((totals, item) => {
      totals.numOfPayments += parseInt(getFieldValue(item, 'numOfPayments')) || 0;
      totals.annualLeasePayment += parseFloat(getFieldValue(item, 'annualLeasePayment')) || 0;
      return totals;
    }, { numOfPayments: 0, annualLeasePayment: 0 });
  };

  const totals = calculateTotals();

  return (
      <>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Schedule J - Leased Machinery, Equipment and Vehicles</Typography>
        {scheduleItems.map((item, index) => (
            <Box key={`${item.borrowerId}-${item.index}`} mb={2}>
              <Box display="flex" gap={2} mb={1}>
                <TextField
                    label="Leaser"
                    value={getFieldValue(item, 'leaser')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'leaser', e.target.value)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Description"
                    value={getFieldValue(item, 'description')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'description', e.target.value)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Purchase Option"
                    value={getFieldValue(item, 'purchaseOption')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'purchaseOption', e.target.value)}
                    InputProps={{ readOnly: readOnly }}
                />
                <TextField
                    label="Num of Payments"
                    type="number"
                    value={getFieldValue(item, 'numOfPayments')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'numOfPayments', parseInt(e.target.value) || '')}
                    InputProps={{ readOnly: readOnly }}
                />
                <CurrencyTextField
modifyValueOnWheel={false}
                    variant="outlined"
                    label="Annual Lease Payment"
                    value={getFieldValue(item, 'annualLeasePayment')}
                    onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'annualLeasePayment', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                    InputProps={{ readOnly: readOnly }}
                />

                {(proforma || !readOnly) && (
                    <IconButton onClick={() => handleDeleteItem(item.borrowerId || selectedBorrowerLocalId, item.index)}>
                      <DeleteIcon />
                    </IconButton>
                )}
              </Box>
            </Box>
        ))}
        {(proforma || !readOnly) && (
            <Box display="flex" justifyContent="flex-start" mb={2}>
              <Button variant="contained" onClick={handleAddItem}>
                Add Item
              </Button>
            </Box>
        )}
        {(readOnly || proforma) && (
            <Box display="flex" gap={2} my={3} justifyContent="flex-end">
              <TextField
                  variant="outlined"
                  label="Total Num of Payments"
                  value={totals.numOfPayments}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
              <CurrencyTextField
                  variant="outlined"
                  label="Total Annual Lease Payment"
                  value={totals.annualLeasePayment}
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ style: { fontWeight: 'bold' } }}
                  inputProps={{ style: { fontWeight: 'bold' } }}
              />
            </Box>
        )}
      </>
  );
});

export default ScheduleJ;