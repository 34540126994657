import React from 'react';
import {observer} from 'mobx-react-lite';
import {Grid, TextField, IconButton, Typography, Box, Button} from '@mui/material';
import {Delete as DeleteIcon} from '@mui/icons-material';
import loanDetailStore from '../store';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const CurrentLiabilities = observer(({borrowers, consolidated, proforma}) => {
    const calculateCurrentTotal = (field) => {
        return borrowers.reduce((sum, borrower) => {
            const value = parseFloat(borrower.balanceSheet?.currentLiabilities?.[field]) || 0;
            return sum + value;
        }, 0);
    };

    const calculateElims = (field) => {
        return parseFloat(borrowers[0].balanceSheet?.currentLiabilities?.[`${field}Elims`] || 0);
    };

    const calculateConsolidated = (field) => {
        return calculateCurrentTotal(field) - calculateElims(field);
    };

    const calculateArrayFieldSum = (schedule, field) => {
        return (borrowers || []).reduce((sum, borrower) => {
            const array = (borrower?.bsas || {})[schedule] || [];
            const arraySum = array.reduce((innerSum, item) => {
                const value = parseFloat(item?.[field] || 0);
                return innerSum + value;
            }, 0);
            return sum + arraySum;
        }, 0);
    };

    const calculateAccruedInterestSum = () => {
        return ['a', 'b', 'c'].reduce((sum, schedule) => {
            return sum + calculateArrayFieldSum(schedule, 'accruedInterest');
        }, 0);
    };

    const calculateCurrentMaturitySum = () => {
        return ['a', 'b'].reduce((sum, schedule) => {
            return sum + calculateArrayFieldSum(schedule, 'annualPayment');
        }, 0);
    };

    const calculateAccountsPayableTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const accountsPayable = borrower.balanceSheet?.accountsPayable || [];
            const accountsPayableSum = accountsPayable.reduce((innerSum, account) => {
                const value = parseFloat(account.amount || 0);
                return innerSum + value;
            }, 0);
            return sum + accountsPayableSum;
        }, 0);
    };

    const calculateAccountsPayableConsolidatedTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const accountsPayable = borrower.balanceSheet?.accountsPayable || [];
            const accountsPayableSum = accountsPayable.reduce((innerSum, account) => {
                const value = parseFloat(account.amount || 0) - parseFloat(account.elims || 0);
                return innerSum + value;
            }, 0);
            return sum + accountsPayableSum;
        }, 0);
    };

    const calculateAccountsPayableChangeTotal = () => {
        return borrowers.reduce((sum, borrower) => {
            const accountsPayable = borrower.balanceSheet?.accountsPayable || [];
            const accountsPayableSum = accountsPayable.reduce((innerSum, account) => {
                const value = parseFloat(account.change || 0);
                return innerSum + value;
            }, 0);
            return sum + accountsPayableSum;
        }, 0);
    };

    const calculateAccountsPayableProformaTotal = () => {
        return calculateAccountsPayableConsolidatedTotal() + calculateAccountsPayableChangeTotal();
    };

    const calculateConsolidatedTotal = () => {
        return (
            calculateArrayFieldSum('c', 'balance') +
            calculateConsolidated('incomeTaxPayable') +
            calculateConsolidated('realEstateTaxesDue') +
            calculateConsolidated('rentsDue') +
            calculateAccruedInterestSum() +
            calculateCurrentMaturitySum() +
            calculateAccountsPayableConsolidatedTotal()
        );
    };

    const calculateProformaTotal = () => {
        return (
            calculateArrayFieldSum('c', 'balance') +
            calculateProforma('incomeTaxPayable') +
            calculateProforma('realEstateTaxesDue') +
            calculateProforma('rentsDue') +
            calculateAccruedInterestSum() +
            calculateCurrentMaturitySum() +
            calculateAccountsPayableProformaTotal()
        );
    };

    const calculateProforma = (field) => {
        return calculateConsolidated(field) + (loanDetailStore.loanData.balanceSheetProforma?.currentLiabilities?.[`${field}Change`] || 0);
    };

    const updateProformaField = (field, value) => {
        loanDetailStore.updateLoanData({
            balanceSheetProforma: {
                ...loanDetailStore.loanData.balanceSheetProforma,
                currentLiabilities: {
                    ...loanDetailStore.loanData.balanceSheetProforma?.currentLiabilities,
                    [field]: value,
                },            },
        });
    };

    const {
        addBalanceSheetField,
        updateBalanceSheetField,
        deleteBalanceSheetField,
        updateBorrowerBalanceSheet
    } = loanDetailStore;

    const renderLiabilityRow = (label, value, onChange, readOnly = false, elimsOrChange = null, onChangeElimsOrChange = null) => (
        <Box display="flex" width="100%" mb={2}>
            <Box width="100%" display="flex" justifyContent={elimsOrChange === null && (consolidated || proforma) ? "flex-end" : "flex-start"}>
                <Box width={elimsOrChange !== null || (!consolidated && !proforma) ? "33%" : "34%"} pr={1} justifyContent={"flex-end"}>
                    <CurrencyTextField
                        variant="outlined"
                        label={label}
                        value={value}
                        onChange={onChange}
                        InputProps={{ readOnly: readOnly }}
                        fullWidth
                    />
                </Box>
                {(consolidated || proforma) && (
                    <>
                        <Box width="33%" pr={1}>
                            {elimsOrChange !== null ? (
                                <CurrencyTextField
modifyValueOnWheel={false}
                                    variant="outlined"
                                    label={consolidated ? "Elims" : "Change"}
                                    value={elimsOrChange}
                                    onChange={onChangeElimsOrChange}
                                    fullWidth
                                />
                            ) : (
                                <Box /> // Empty box for spacing
                            )}
                        </Box>
                        <Box width="33%">
                            {elimsOrChange !== null ? (
                                <CurrencyTextField
                                    variant="outlined"
                                    label={consolidated ? "Consolidated" : "Proforma"}
                                    value={consolidated ? (value - elimsOrChange) : (value + elimsOrChange)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                />
                            ) : (
                                <Box /> // Empty box for spacing
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );

    return (
        <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" my={2}>
                Current Liabilities
            </Typography>
            {renderLiabilityRow(
                "Current Debt - Mat. L.S. Corp Credit (Schedule C)",
                calculateArrayFieldSum('c', 'balance'),
                () => {},
                true
            )}

            {renderLiabilityRow(
                "Income Tax Payable",
                calculateCurrentTotal('incomeTaxPayable'),
                (e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentLiabilities.incomeTaxPayable', parseFloat(e.target.value.replace(/,/g, "")) || ""),
                consolidated || proforma,
                consolidated ? (borrowers[0].balanceSheet?.currentLiabilities?.incomeTaxPayableElims || "") :
                    (loanDetailStore.loanData.balanceSheetProforma?.currentLiabilities?.incomeTaxPayableChange || ""),
                consolidated ?
                    (e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentLiabilities.incomeTaxPayableElims', parseFloat(e.target.value.replace(/,/g, "")) || "") :
                    (e) => updateProformaField('incomeTaxPayableChange', parseFloat(e.target.value.replace(/,/g, "")) || "")
            )}

            {renderLiabilityRow(
                "Real Estate Taxes Due",
                calculateCurrentTotal('realEstateTaxesDue'),
                (e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentLiabilities.realEstateTaxesDue', parseFloat(e.target.value.replace(/,/g, "")) || ""),
                consolidated || proforma,
                consolidated ? (borrowers[0].balanceSheet?.currentLiabilities?.realEstateTaxesDueElims || "") :
                    (loanDetailStore.loanData.balanceSheetProforma?.currentLiabilities?.realEstateTaxesDueChange || ""),
                consolidated ?
                    (e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentLiabilities.realEstateTaxesDueElims', parseFloat(e.target.value.replace(/,/g, "")) || "") :
                    (e) => updateProformaField('realEstateTaxesDueChange', parseFloat(e.target.value.replace(/,/g, "")) || "")
            )}

            {renderLiabilityRow(
                "Rents Due",
                calculateCurrentTotal('rentsDue'),
                (e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentLiabilities.rentsDue', parseFloat(e.target.value.replace(/,/g, "")) || ""),
                consolidated || proforma,
                consolidated ? (borrowers[0].balanceSheet?.currentLiabilities?.rentsDueElims || "") :
                    (loanDetailStore.loanData.balanceSheetProforma?.currentLiabilities?.rentsDueChange || ""),
                consolidated ?
                    (e) => updateBorrowerBalanceSheet(borrowers[0].id, 'currentLiabilities.rentsDueElims', parseFloat(e.target.value.replace(/,/g, "")) || "") :
                    (e) => updateProformaField('rentsDueChange', parseFloat(e.target.value.replace(/,/g, "")) || "")
            )}

            {renderLiabilityRow(
                "Accrued Interest (Schedule A, B, C)",
                calculateAccruedInterestSum(),
                () => {},
                true
            )}

            {renderLiabilityRow(
                "Current Maturity of Non-Current Debt (Schedule A, B)",
                calculateCurrentMaturitySum(),
                () => {},
                true
            )}

            <Typography variant="subtitle1" my={2}>
                Accounts Payable (one year)
            </Typography>
            {borrowers.map((borrower) => (
                <Box key={borrower.id} mb={2}>
                    <Typography variant="subtitle2">{borrower.name}</Typography>
                    {borrower.balanceSheet?.accountsPayable?.map((account, index) => (
                        <Box key={index} display="flex" gap={2} mb={2}>
                            <TextField
                                label="Description"
                                value={account.description}
                                onChange={(e) => updateBalanceSheetField(borrower.id, 'accountsPayable', index, {
                                    ...account,
                                    description: e.target.value
                                })}
                                InputProps={{readOnly: consolidated || proforma}}
                            />
                            {consolidated && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={account.amount}
                                        InputProps={{readOnly: true}}
                                    />
                                    <CurrencyTextField
modifyValueOnWheel={false}
                                        variant="outlined"
                                        label="Elims"
                                        value={account.elims || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'accountsPayable', index, {
                                            ...account,
                                            elims: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        })}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Consolidated"
                                        value={(parseFloat(account.amount || 0) - parseFloat(account.elims || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                    />
                                </>
                            )}
                            {proforma && (
                                <>
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Current"
                                        value={(parseFloat(account.amount || 0) - parseFloat(account.elims || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                    />
                                    <CurrencyTextField
modifyValueOnWheel={false}
                                        variant="outlined"
                                        label="Change"
                                        value={account.change || ""}
                                        onChange={(e) => updateBalanceSheetField(borrower.id, 'accountsPayable', index, {
                                            ...account,
                                            change: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                        })}
                                    />
                                    <CurrencyTextField
                                        variant="outlined"
                                        label="Proforma"
                                        value={(parseFloat(account.amount || 0) - parseFloat(account.elims || 0) + parseFloat(account.change || 0)).toFixed(2)}
                                        InputProps={{readOnly: true}}
                                    />
                                </>
                            )}
                            {!consolidated && !proforma && (
                                <CurrencyTextField
modifyValueOnWheel={false}
                                    variant="outlined"
                                    label="Amount"
                                    value={account.amount}
                                    onChange={(e) => updateBalanceSheetField(borrower.id, 'accountsPayable', index, {
                                        ...account,
                                        amount: parseFloat(e.target.value.replace(/,/g, "")) || ''
                                    })}
                                />
                            )}
                            {!consolidated && !proforma && (
                                <IconButton
                                    onClick={() => deleteBalanceSheetField(borrower.id, 'accountsPayable', index)}>
                                    <DeleteIcon/>
                                </IconButton>
                            )}
                        </Box>
                    ))}
                    {!consolidated && !proforma && (
                        <Box display="flex" justifyContent="flex-start" mb={2}>
                            <Button variant="contained"
                                    onClick={() => addBalanceSheetField(borrower.id, 'accountsPayable')}>
                                Add Row
                            </Button>
                        </Box>
                    )}
                </Box>
            ))}

            <Box display="flex" flexDirection="row" gap={2} my={2}>
                <Grid item xs={12} md={6}>
                    <Typography>Total Current Liabilities:</Typography>
                </Grid>
                <Grid item xs={12} md={6} align="right">
                    <Typography>
                        {(
                            consolidated ? calculateConsolidatedTotal() :
                                proforma ? calculateProformaTotal() :
                                    calculateArrayFieldSum('c', 'balance') +
                                    calculateCurrentTotal('incomeTaxPayable') +
                                    calculateCurrentTotal('realEstateTaxesDue') +
                                    calculateCurrentTotal('rentsDue') +
                                    calculateAccruedInterestSum() +
                                    calculateCurrentMaturitySum() +
                                    calculateAccountsPayableTotal()
                        ).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
                    </Typography>
                </Grid>
            </Box>
        </Grid>
    );
});

export default CurrentLiabilities;