import React, {useCallback, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Typography, TextField, Button} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';
import loanDetailStore from '../store';

const ScheduleE = observer(({borrowerData, readOnly, proforma}) => {
    const {
        updateBSAData,
        addBSAItem,
        deleteBSAItem,
        updateBSAProformaData,
        addBSAProformaItem,
        deleteBSAProformaItem,
        selectedBorrowerId
    } = loanDetailStore;

    const selectedBorrowerLocalId = useMemo(() => {
        let result;
        if (readOnly || proforma) {
            result = borrowerData.find(borrower => borrower.is_principal === true)["id"];
        } else {
            result = selectedBorrowerId;
        }
        if (!result) {
            console.warn('No borrower found!');
        }
        return result;
    }, [selectedBorrowerId, readOnly, proforma, borrowerData]);

    const selectedBorrower = useMemo(() => {
        return borrowerData.find(borrower => borrower.id === selectedBorrowerLocalId);
    }, [selectedBorrowerLocalId, borrowerData]);

    const handleUpdateItem = useCallback((borrowerId, index, field, value) => {
        if (proforma) {
            updateBSAProformaData(borrowerId, 'e', index, field, value);
        } else {
            updateBSAData(borrowerId, 'e', index, field, value);
        }
    }, [updateBSAData, updateBSAProformaData, proforma]);

    const handleAddItem = useCallback(() => {
        if (proforma) {
            addBSAProformaItem('e');
        } else {
            addBSAItem(selectedBorrowerLocalId, 'e');
        }
    }, [addBSAItem, addBSAProformaItem, proforma, selectedBorrowerLocalId]);

    const handleDeleteItem = useCallback((borrowerId, index) => {
        if (proforma) {
            const borrower = borrowerData.find(b => b.id === borrowerId);
            if (borrower && borrower.bsas?.e) {
                const originalIndex = borrower.bsas.e.findIndex((item, idx) => idx === index);
                if (originalIndex !== -1) {
                    deleteBSAProformaItem(borrowerId, 'e', originalIndex);
                }
            }
        } else {
            deleteBSAItem(borrowerId, 'e', index);
        }
    }, [deleteBSAItem, deleteBSAProformaItem, proforma, borrowerData]);

    const getFieldValue = useCallback((item, field) => {
        return proforma ? (item.proforma?.[field] ?? item[field] ?? '') : (item[field] ?? '');
    }, [proforma]);

    const renderScheduleItems = () => {
        if (readOnly) {
            return borrowerData.flatMap(borrower => borrower.bsas?.e || []);
        }

        if (proforma) {
            return borrowerData.flatMap((borrower, borrowerIndex) =>
                (borrower.bsas?.e || []).map((item, itemIndex) => ({
                    ...item,
                    ...(item.proforma || {}),
                    borrowerId: borrower.id,
                    index: itemIndex,  // Add this line
                }))
            );
        }

        return (selectedBorrower.bsas?.e || []).map((item, index) => ({...item, index}));
    };

    const scheduleItems = renderScheduleItems();

    const calculateTotals = () => {
        return scheduleItems.reduce((totals, item) => {
            totals.amount += parseFloat(getFieldValue(item, 'amount')) || 0;
            totals.interest += parseFloat(getFieldValue(item, 'interest')) || 0;
            totals.principal += parseFloat(getFieldValue(item, 'principal')) || 0;
            totals.value += parseFloat(getFieldValue(item, 'value')) || 0;
            return totals;
        }, {amount: 0, interest: 0, principal: 0, value: 0});
    };

    const totals = calculateTotals();

    return (
        <>
            <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Schedule E - Notes Receivable</Typography>
            {scheduleItems.map((item, index) => (
                <Box key={`${item.borrowerId}-${item.index}`} mb={2}>
                    <Box display="flex" gap={2} mb={1}>
                        <TextField
                            label="Received From"
                            value={getFieldValue(item, 'receivedFrom')}
                            onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'receivedFrom', e.target.value)}
                            InputProps={{readOnly: readOnly}}
                        />
                        <CurrencyTextField
modifyValueOnWheel={false}
                            variant="outlined"
                            label="Amount"
                            value={getFieldValue(item, 'amount')}
                            onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'amount', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                            InputProps={{readOnly: readOnly}}
                        />
                        <TextField
                            label="Rate"
                            type="number"
                            value={getFieldValue(item, 'rate')}
                            onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'rate', parseFloat(e.target.value) || '')}
                            InputProps={{readOnly: readOnly}}
                        />
                        <CurrencyTextField
                            variant="outlined"
                            label="Interest"
                            value={(parseFloat(getFieldValue(item, 'amount')) || 0) * (parseFloat(getFieldValue(item, 'rate')/100) || 0)}
                            InputProps={{ readOnly: true }}
                        />
                        <CurrencyTextField
modifyValueOnWheel={false}
                            variant="outlined"
                            label="Principal"
                            value={getFieldValue(item, 'principal')}
                            onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'principal', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                            InputProps={{readOnly: readOnly}}
                        />
                        <CurrencyTextField
modifyValueOnWheel={false}
                            variant="outlined"
                            label="Value"
                            value={getFieldValue(item, 'value')}
                            onChange={(e) => handleUpdateItem(item.borrowerId || selectedBorrowerLocalId, index, 'value', parseFloat(e.target.value.replace(/,/g, "")) || '')}
                            InputProps={{readOnly: readOnly}}
                        />

                        {(proforma || !readOnly) && (
                            <IconButton onClick={() => handleDeleteItem(item.borrowerId || selectedBorrowerLocalId, item.index)}>
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            ))}
            {(proforma || !readOnly) && (
                <Box display="flex" justifyContent="flex-start" mb={2}>
                    <Button variant="contained" onClick={handleAddItem}>
                        Add Item
                    </Button>
                </Box>
            )}
            {(readOnly || proforma) && (
                <Box display="flex" gap={2} my={3} justifyContent="flex-end">
                    <CurrencyTextField
                        variant="outlined"
                        label="Total Amount"
                        value={totals.amount}
                        InputProps={{readOnly: true}}
                        InputLabelProps={{style: {fontWeight: 'bold'}}}
                        inputProps={{style: {fontWeight: 'bold'}}}
                    />
                    <CurrencyTextField
                        variant="outlined"
                        label="Total Interest"
                        value={totals.interest}
                        InputProps={{readOnly: true}}
                        InputLabelProps={{style: {fontWeight: 'bold'}}}
                        inputProps={{style: {fontWeight: 'bold'}}}
                    />
                    <CurrencyTextField
                        variant="outlined"
                        label="Total Principal"
                        value={totals.principal}
                        InputProps={{readOnly: true}}
                        InputLabelProps={{style: {fontWeight: 'bold'}}}
                        inputProps={{style: {fontWeight: 'bold'}}}
                    />
                    <CurrencyTextField
                        variant="outlined"
                        label="Total Value"
                        value={totals.value}
                        InputProps={{readOnly: true}}
                        InputLabelProps={{style: {fontWeight: 'bold'}}}
                        inputProps={{style: {fontWeight: 'bold'}}}
                    />
                </Box>
            )}
        </>
    );
});

export default ScheduleE;