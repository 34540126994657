import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, Button, MenuItem } from '@mui/material';
import loanDetailStore from '../store';
import Grid from '@mui/material/Grid';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const UseOfFunds = observer(() => {
    const { loanData, updateLoanData, addUseOfFunds, removeUseOfFunds } = loanDetailStore;

    const handleUseOfFundsChange = (index, field, value) => {
        updateLoanData({
            application: {
                ...loanData.application,
                useOfFunds: loanData.application.useOfFunds.map((item, i) =>
                    i === index ? { ...item, [field]: value } : item
                ),
            },
        });
    };

    const loanPurposeOptions = ['Purchase Real Estate', 'Refinance Real Estate', 'Improvements', 'Refinance Non-Real Estate', 'Cash Out', 'Closing Fees'];

    const total = loanData?.application?.useOfFunds?.reduce((sum, item) => sum + (Number(item.amount) || 0), 0);

    return (
        <Box>
            <Typography variant="h6" mb={1}>Use of Funds</Typography>
            {loanData?.application?.useOfFunds?.map((item, index) => (
                <Grid container spacing={2} key={index} alignItems="center" mb={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            select
                            label="Loan Purpose"
                            value={item.loanPurpose || ''}
                            onChange={(e) => handleUseOfFundsChange(index, 'loanPurpose', e.target.value)}
                            fullWidth
                        >
                            {loanPurposeOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Description"
                            value={item.description || ''}
                            onChange={(e) => handleUseOfFundsChange(index, 'description', e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <CurrencyTextField
                            modifyValueOnWheel={false}
                            variant="outlined"
                            label="Amount"
                            value={item.amount || ''}
                            onChange={(e, value) => handleUseOfFundsChange(index, 'amount', value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <Button variant="contained" color="secondary" onClick={() => removeUseOfFunds(index)} fullWidth>
                            Remove
                        </Button>
                    </Grid>
                </Grid>
            ))}
            <Grid container spacing={2} alignItems="center" mb={2}>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={3}>
                    <CurrencyTextField
                        disabled
                        value={total || 0}
                        variant="outlined"
                        label="Total"
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Button variant="contained" onClick={addUseOfFunds}>
                Add additional row
            </Button>
        </Box>
    );
});

export default UseOfFunds;