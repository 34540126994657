import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Paper,
    Typography,
    TextField,
    Grid,
    MenuItem,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield/dist/CurrencyTextField';

const RealEstateSecurity = observer(({ loanData, updateLoanData }) => {
    const [commodities, setCommodities] = useState(loanData?.loanSummary?.commodities || []);

    const handleInputChange = (field, value) => {
        updateLoanData({
            loanSummary: {
                ...loanData.loanSummary,
                [field]: value,
            },
        });
    };

    const handleCommodityChange = (index, field, value) => {
        const newCommodities = [...commodities];
        newCommodities[index][field] = value;
        setCommodities(newCommodities);
        updateLoanData({
            loanSummary: {
                ...loanData.loanSummary,
                commodities: newCommodities,
            },
        });
    };

    const addCommodity = () => {
        setCommodities([...commodities, { type: '', percentage: '' }]);
    };

    const totalPercentage = commodities.reduce((sum, commodity) => sum + Number(commodity.percentage || 0), 0);

    return (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
            <Typography variant="subtitle1" gutterBottom>Real Estate Security:</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField
                                fullWidth
                                label="Total Acres"
                                value={loanData?.application?.security?.acres || ''}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                label="Tillable"
                                value={loanData?.loanSummary?.tillable || ''}
                                onChange={(e) => handleInputChange('tillable', e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                label="Irrigated Acres"
                                value={loanData?.loanSummary?.irrigatedAcres || ''}
                                onChange={(e) => handleInputChange('irrigatedAcres', e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                label="County"
                                value={loanData?.application?.security?.county || ''}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                label="State"
                                value={loanData?.application?.security?.state || ''}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item>
                            <CurrencyTextField
modifyValueOnWheel={false}
                                fullWidth
                                variant="outlined"
                                label="Value"
                                value={loanData?.loanSummary?.value || ''}
                                onChange={(e, value) => handleInputChange('value', value)}
                            />
                        </Grid>
                        <Grid item>
                            <CurrencyTextField
modifyValueOnWheel={false}
                                fullWidth
                                variant="outlined"
                                label="Net Property Income"
                                value={loanData?.loanSummary?.netPropertyIncome || ''}
                                onChange={(e, value) => handleInputChange('netPropertyIncome', value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" gutterBottom>COMMODITIES GROWN ON SEC.</Typography>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Commodity Type</TableCell>
                                    <TableCell align="right">% OF TOTAL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {commodities.map((commodity, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <TextField
                                                select
                                                fullWidth
                                                value={commodity.type}
                                                onChange={(e) => handleCommodityChange(index, 'type', e.target.value)}
                                            >
                                                <MenuItem value="Annual">Annual</MenuItem>
                                                <MenuItem value="Perennial">Perennial</MenuItem>
                                            </TextField>
                                        </TableCell>
                                        <TableCell align="right">
                                            <TextField
                                                type="number"
                                                value={commodity.percentage}
                                                onChange={(e) => handleCommodityChange(index, 'percentage', e.target.value)}
                                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell align="right">{totalPercentage}%</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button onClick={addCommodity} variant="contained" sx={{ mt: 1 }}>Add Commodity</Button>
                </Grid>
            </Grid>
        </Paper>
    );
});

export default RealEstateSecurity;